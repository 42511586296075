




















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class EventsNavTabs extends Vue {
  @Prop({ required: true }) selectedRegionals!: Array<string>
  statusTabs = [
    {
      label: 'Ativos',
      query: undefined
    },
    {
      label: 'Realizados',
      query: 'completed'
    },
    {
      label: 'Cancelados',
      query: 'canceled'
    },
    {
      label: 'Demonstração',
      query: 'demo'
    }
  ]

  selectedTab = {}

  @Watch('selectedRegionals')
  regionalChange () {
    const newRoute = {
      query: {
        status: this.$route.query.status,
        regional: this.selectedRegionals
      }
    }

    this.switchRoute(newRoute)
  }

  tabChange (tab: number) {
    const route = this.statusTabs[tab]
    this.selectedTab = route

    this.$emit('selectedTab', this.selectedTab)

    const newRoute = {
      query: {
        status: route.query,
        regional: this.selectedRegionals
      }
    }

    this.switchRoute(newRoute)
  }

  switchRoute (newRoute: Record<string, any>) {
    if (this.$route.name === 'Events' && this.$router.resolve(newRoute).href !== this.$route.fullPath) {
      this.$router.push(newRoute)
    }
  }
}
