





























































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'

import EventsNavTabs from '@/partials/components/Events/NavTabs.vue'
import RegionalsSideNav from '@/partials/components/RegionalsSideNav.vue'
import View16 from '@carbon/icons-vue/es/view/16'
import TablePagination from '@/partials/tables/Pagination.vue'
import PermissionsMixin from '@/mixins/permissionsMixin'

const eventsPermissionsModule = namespace('eventsPermissions')

@Component({
  components: {
    TablePagination,
    EventsNavTabs,
    RegionalsSideNav,
    View16
  }
})
export default class EventsIndex extends Mixins(PermissionsMixin) {
  selectedTab = {}
  selectedRegionals: Array<string> = []
  regionals = []
  events: Array<EventEntity> = []
  eventsTotal = 0
  @eventsPermissionsModule.Getter isSubscriberOnEvent!: (eventId: string) => boolean

  created () {
    if (this.$route.query.regional) {
      this.selectedRegionals = Array.isArray(this.$route.query.regional) ? this.$route.query.regional as Array<string> : [this.$route.query.regional]
    }

    this.fetchEvents()
  }

  fetchEvents (page = 1, perPage = 20) {
    const route = 'events'
    const { status, regional } = this.$route.query

    const perPageSnake = snakeCaseKeys({ perPage })
    axios.get(route, {
      params: {
        'page[number]': page,
        ...perPageSnake,
        status: this.hasPermission(this.permissions.EVENTTAB.READ) ? status : 'active,completed',
        regional: regional
      }
    })
      .then(response => {
        this.events = camelCaseKeys(response.data.data, { deep: true })
        this.eventsTotal = response.data.total
      })
  }

  @Watch('$route.query.status')
  @Watch('$route.query.regional')
  filterEvents (_: string, __: string, page = 1, perPage = 20) {
    this.fetchEvents(page, perPage)
  }

  onPageChange (pagination: Pagination) {
    this.filterEvents('', '', pagination.page, pagination.length)
  }
}
